import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import SEO from "../components/seo"
import ChakraMeanings from "../components/chakrameanings"
import ChakraIntroduction from "../components/chakraintroduction"

const StyledContainer = styled.div`
  width: 100%;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: center;
`

export default function ChakraPage({ data }) {
  const [intro, setIntro] = useState(true)
  const chakras = data.chakras.nodes
  const random = Math.floor(Math.random() * 7)

  function handleClick(e) {
    e.preventDefault()
    setIntro(!intro)
  }

  return (
    <>
      <SEO title="Chakras" />
      <StyledContainer>
        {intro ? (
          <ChakraIntroduction handleClick={e => handleClick(e)} />
        ) : (
          <ChakraMeanings chakras={chakras} random={random} />
        )}
      </StyledContainer>
    </>
  )
}

export const query = graphql`
  query chakrasQuery {
    chakras: allSanityChakras {
      nodes {
        astrology
        attributes
        balance
        colour
        description
        element
        id
        imbalance
        name
        organs
        crystals
        oils
        image {
          asset {
            gatsbyImageData(
              width: 500
              height: 500
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [WEBP, AVIF, AUTO]
            )
          }
        }
      }
    }
  }
`
