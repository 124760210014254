import React from "react"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"

export default function ChakraIntroduction(props) {
  return (
    <Card className="w-100" style={{maxWidth: `50rem`}}>
      <Card.Header className="m-3 p-3 text-center" style={{textShadow: `0.2rem 0.2rem #412456`}}><h3>Understanding Your Chakras</h3></Card.Header>
      <Card.Body>
        <Card.Text className="w-75 mx-auto" style={{color: `var(--gold)`, fontSize: `1.4rem`}}>
          The seven chakras serve as our energy or psychic centres. They are interconnected within each of us but also the connection between each of us, individually, and the rest of the universe around us.
        </Card.Text>
        <Card.Text className="w-75 mx-auto"style={{color: `var(--gold)`, fontSize: `1.4rem`}}>
          Located vertically, starting at the base of the spine, these spinning wheels of energy emit their light out into the aura, vibrating at various frequencies, starting with a dense vibration in the base chakra, getting finer and faster as they head upwards towards the crown chakra.
        </Card.Text>
        <Card.Text className="w-75 mx-auto" style={{color: `var(--gold)`, fontSize: `1.4rem`}}>
         Reveal a chakra, or one of the elements of life that it governs, by clicking below. You'll come to understand how to tell when yours are balanced, or even out of balance, what signs to look for and what oils and crystals can help to realign your chakras andd have them vibrating at maxium efficiency for a happy and peaceful existence.
        </Card.Text>
        <Card.Text className="w-75 mx-auto" style={{color: `var(--gold)`, fontSize: `1.4rem`}}>
         Each Ask The Tarot reading includes at least one chakra card, which can help to identify any energy blocks or imbalances that may require some attention and care on your part to restore to full spinning and vibrating harmony.
        </Card.Text>
      </Card.Body>
      <Card.Footer className="m-0 p-0">
        <Button className="w-100" type="submit" onClick={e => props.handleClick(e)}>
          Reveal A Chakra
        </Button>
      </Card.Footer>
    </Card>
  )
}
