import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"
import Modal from "react-modal"

export default function ChakraMeanings(props) {
  const chakra = props.chakras[`${props.random}`]

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const closeModal = () => {
    setModalIsOpen(false)
  }

  const openModal = () => {
    setModalIsOpen(true)
  }

  const closeChakraModal = () => {
    setModalIsOpen(false)
    navigate("/readings")
  }

  return (
    <>
      <Card className="ChakraFront">
        <Card.Body className="d-flex justify-content-center">
          <GatsbyImage
            image={chakra.image.asset.gatsbyImageData}
            alt={chakra.name}
          />
        </Card.Body>
        <Card.Footer className="m-0 p-0">
          <Button className="w-100 m-0 p-2" type="button" onClick={openModal}>
            {chakra.name} CHAKRA
          </Button>
        </Card.Footer>
      </Card>

      <div className="m-3">
        <Link to="/readings">Check Your Chakra Alignment</Link>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
      >
        <div className="chakra-modal-content modal-content">
          <div className="close-modal">
            <button onClick={closeModal}>X</button>
          </div>
          <div className="chakra-modal-view">
            <div className="chakra-modal-header">
              <h2>The {chakra.name} Chakra</h2>
              <p>{chakra.description}</p>
            </div>
            <div className="chakra-modal-desc">
              <div>
                <h5>Attributes: </h5>
                <p>{chakra.attributes}</p>
              </div>
              <div>
                <h5>Colour: </h5>
                <p>{chakra.colour}</p>
              </div>
              <div>
                <h5>Astrology: </h5>
                <p>{chakra.astrology}</p>
              </div>
              <div>
                <h5>Element: </h5>
                <p>{chakra.element}</p>
              </div>
              <div>
                <h5>Organs: </h5>
                <p>{chakra.organs}</p>
              </div>
              <div>
                <h5>Crystals/Gems/Stones: </h5>
                <p>{chakra.crystals}</p>
              </div>
              <div>
                <h5>Oils: </h5>
                <p>{chakra.oils}</p>
              </div>
            </div>
            {/*closes modal desc*/}
            <div className="chakra-modal-signs">
              <div>
                <h5>Signs Of A Balanced Chakra: </h5>
                <p>{chakra.balance} </p>
              </div>
              <div>
                <h5>Signs Of An Imbalanced Chakra: </h5>
                <p>{chakra.imbalance} </p>
              </div>
            </div>{" "}
            {/*Closes modal signs */}
          </div>{" "}
          {/*Closers Modal view */}
          <div className="close-modal-new-chakra">
            <button onClick={closeChakraModal}>
              Check Your Chakra Alignment
            </button>
          </div>
        </div>{" "}
        {/* Closes Modal Content */}
      </Modal>
    </>
  )
}
